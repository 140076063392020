.title h1 {
  font-family: Round !important;
  font-size: 40px !important;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px !important;
  line-height: 70px;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.541);
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  text-overflow: clip !important;
  white-space: nowrap !important;
}

.go-back:hover img {
  transform: translateX(-20px);
}

.page-back:hover div img {
  transform: translateX(-20px);
}

.page-forward:hover div img {
  transform: translateX(20px) scaleX(-1);
}

.go-back:hover,
.page-back:hover,
.page-forward:hover {
  cursor: pointer;
}

.go-back img,
.page-back img,
.page-forward img {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 00%;
  transition: transform ease-in 0.05s;
}

.go-back,
.page-back,
.page-forward {
  margin-bottom: 20px;
  float: left;
  user-select: none;
}

.page-back h1,
.page-forward h1 {
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.541);
  font-family: Round !important;
  line-height: 50px;
  margin-bottom: 0;
  text-align: center;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  font-size: 20px;
  white-space: nowrap !important;
}

@media only screen and (max-width: 990px) {
  .page-back h1,
  .page-forward h1 {
    font-size: 4vmin !important;
  }
}

.page-forward img {
  transform: scaleX(-1);
}

.bottom-space {
  margin-bottom: 30px !important;
}
.top-space {
  margin-top: 30px !important;
}

.viewer-wrapper {
  overflow: hidden;
  transition: max-height ease-out 0.3s, opacity ease-out 0.3s;
}

.viewer {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  user-select: none;
}

.row-shadow {
  box-shadow: 0px 3px 30px 6px rgba(0, 0, 0, 0.2);
}

.viewer-container {
  position: relative;
  width: 100%;
  min-height: 150px;
  overflow: hidden;
  user-select: none;
  border-radius: 30px;
  box-shadow: 0px 3px 15px 6px rgba(0, 0, 0, 0.4);
  margin-top: 30px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .viewer-container {
    border-radius: 10px !important;
  }
}

.viewer-inner-container {
  transition: flex ease-out 0.5s, max-width ease-out 0.5s;
}

.viewer-container:hover {
  cursor: pointer;
}

.info-box {
  margin-left: 5% !important;
  width: 85%;
  margin-bottom: 20px;
}

.info-box h1 {
  font-family: Round !important;
  font-size: 40px !important;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.377);
}
.info-box h2 {
  font-family: Round !important;
  font-size: 30px !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.336);
}

.info-box h3 {
  font-family: Round !important;
  font-size: 25px !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.336);
}

.info-box p {
  font-family: Round !important;
  font-size: 15px !important;
}

.wikipedia {
  padding-left: 5px;
}

.alert-text {
  background: rgba(255, 0, 0, 0.329);
  background: linear-gradient(
    -5deg,
    rgba(172, 0, 0, 0.507) 0%,
    rgba(255, 29, 29, 0.329) 100%
  );
  color: rgba(0, 0, 0, 0.377) !important;
}

.note-text {
  background-color: rgba(0, 0, 0, 0.158);
  color: rgba(255, 255, 255, 0.5) !important;
}

.note-text,
.alert-text {
  margin-left: 5px;
  padding: 5px 0px 5px 10px;
  border-radius: 10px;
  line-height: 30px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.171) !important;
}

.map-viewer {
  width: 100%;
  height: 20vw;
  min-height: 200px;
  margin-bottom: 20px;
}

.map-viewer > div {
  border-radius: 30px;
  box-shadow: 0px 3px 15px 6px rgba(0, 0, 0, 0.4);
}

.present,
.past {
  position: relative;
  width: 100%;
  overflow: hidden;
  user-select: none;
}

.present img,
.past > div,
.loading {
  position: relative;
  height: 100%;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
  z-index: 5;
  transition: filter ease-out 1s;
}

.past,
.loading {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

.past > div {
  background-size: cover;
}

.loading {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 6;
  background-image: url("../resources/loading.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  transition: opacity ease-in-out 0s;
}

.fullscreen-toggle,
.color-toggle {
  height: 90px;
}
.color-toggle label,
.fullscreen-toggle label {
  width: 100px !important;
}

.color-toggle .toggle-control input:checked ~ .control {
  background: rgb(201, 44, 208) !important;
  background: linear-gradient(
    90deg,
    rgba(201, 44, 208, 1) 0%,
    rgba(253, 104, 29, 1) 50%,
    rgba(252, 167, 69, 1) 100%
  ) !important;
}

.fullscreen-toggle h1,
.color-toggle h1 {
  text-align: right;
  font-family: Round !important;
  font-size: 30px !important;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.377);
  margin-top: 5px;
}

.fullscreen-toggle > div img,
.color-toggle > div img {
  opacity: 0.5;
  position: absolute;
  height: 50px;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .color-toggle,
  .fullscreen-toggle {
    height: 70px !important;
  }

  .settings-container {
    padding-top: 0px !important;
  }
}

/*
.present img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%) !important;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
*/
