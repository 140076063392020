.content {
  padding-top: 20px;
  z-index: 0;
}

.content-wrapper {
  padding-top: 0px !important;
  min-height: calc(100vh - 160px);
}

.gradient {
  position: relative;
  padding-top: 20px;

  background: rgb(31, 31, 31); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(31, 31, 31, 0) 1%,
    rgba(44, 44, 44, 0.5) 20%,
    rgba(37, 37, 37, 1) 24%,
    rgba(50, 50, 50, 1) 28%,
    rgba(54, 54, 54, 1) 50%,
    rgba(50, 50, 50, 1) 72%,
    rgba(37, 37, 37, 1) 76%,
    rgba(44, 44, 44, 0.5) 80%,
    rgba(31, 31, 31, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(31, 31, 31, 0) 1%,
    rgba(44, 44, 44, 0.5) 20%,
    rgba(37, 37, 37, 1) 24%,
    rgba(50, 50, 50, 1) 28%,
    rgba(54, 54, 54, 1) 50%,
    rgba(50, 50, 50, 1) 72%,
    rgba(37, 37, 37, 1) 76%,
    rgba(44, 44, 44, 0.5) 80%,
    rgba(31, 31, 31, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(31, 31, 31, 0) 1%,
    rgba(44, 44, 44, 0.2) 20%,
    rgba(37, 37, 37, 0.5) 24%,
    rgba(50, 50, 50, 0.5) 28%,
    rgba(54, 54, 54, 0.5) 50%,
    rgba(50, 50, 50, 0.5) 72%,
    rgba(37, 37, 37, 0.5) 76%,
    rgba(44, 44, 44, 0.2) 80%,
    rgba(31, 31, 31, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1f1f', endColorstr='#1f1f1f',GradientType=1 );

  opacity: 1;
}
