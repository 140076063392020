.header {
  color: white;
  height: 80px;
  z-index: 9999;
}

.header-logo {
  background-image: url("../resources/squarelogo-360p-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 60px;
  margin: 5px 0 0 0;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .header-logo {
    background-size: 50px !important;
  }
}

.header-logo:hover {
  cursor: pointer;
}

.search-form {
  position: relative;
  height: 80px;
  width: 100%;
}

.form-inline {
  height: 80px !important;
}

.search-form-textbox,
.search-form-textbox:focus {
  margin-left: auto;
  margin-right: auto !important;
  width: 90% !important;
  text-align: center;
}
